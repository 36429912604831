@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// Main navigation
//================

.main-nav
{
    @include absoluteLeft(0,0);
    background:             $blue-01;
    height:                 100vh;
    @include translate3d(-100%,0,0);
    @include transitions(all .7s $easeOutExpo);
    width:                  360px;
    z-index:                $global-zindex - 1;
    &.active
    {
        @include translate3d(0,0,0);
        .main-nav__menu,.main-nav__submenu
        {
            @include opacity(1);
        }
        .main-nav__menu
        {
            @include translate(0,-70%);
        }
        .main-nav__submenu
        {
            @include translate(0,0);
        }
    }
    .disabled
    {
        pointer-events:         none;
    }
}

.main-nav__menu,.main-nav__submenu
{
    @include absoluteLeft(105px,50%);
    @include opacity(0);
    @include translate(-30px,-70%);
    @include transitions(all $easing-opacity);
    @include transition-delay(200ms);
    li
    {
        color:                  $white;
        font:                   300 4em/1 $roboto;
        margin:                 0 0 10px;
        position:               relative;
        a
        {
            color:                  #a5cdff;
            &:before
            {
                @include absoluteLeft(0,0);
                clip:                   rect(0,0,100px,0);
                content:                attr(data-content);
                color:                  $white;
                @include inlineBlock;
                @include transitions(clip .4s $easeInOutCubic);
                width:                  100%;
            }
        }
        &.active
        {
            a
            {
                color:                  $white;
                &:before
                {
                    display:                none;
                }
            }
        }
        &:hover
        {
            a
            {
                &:before
                {
                    clip:                   rect(0,200px,100px,0);
                }
            }
        }
    }
}

.main-nav__submenu
{
    bottom:                 105px;
    @include translate(-30px,0);
    top:                    auto;
    @include transition-delay(400ms);
    li
    {
        font-size:              1.8em;
        &:last-child
        {
            margin-top:             35px;
        }
    }
}

// Main navigation trigger
//========================

.main-nav__button
{
    @include absoluteLeft(100px,57px);
    cursor:                 pointer;
    height:                 14px;
    line-height:            14px;
    width:                  30px;
    z-index:                $global-zindex;
    &:after {
        @include absoluteLeft(0,7px);
        box-shadow:             0 2px $white, 0 -4px $white, 0 -10px $white;
        content:                " ";
        display:                block;
        height:                 2px;
        @include transitions(all 1s $easeInOutQuad);
        width:                  100%;
    }
    .alternative--skin &
    {
        &:after {
            box-shadow:             0 2px $blue-02, 0 -4px $blue-02, 0 -10px $blue-02;
        }
    }
    .mainnav--opened &,.final--skin &
    {
        &:after {
            box-shadow:             0 2px $white, 0 -4px $white, 0 -10px $white !important;
        }
    }
}