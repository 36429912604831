@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

// Feature bubble
//===============

.feature__line
{
    @include absoluteLeft(0,0);
    height:             $timeline-feature-size;
    @include opacity(0);
    @include transitions(opacity .5s $easeInOutQuart);
    width:              $timeline-feature-size;
    &:before
    {
        @include absoluteLeft(50%,40px);
        background:             $white;
        content:                " ";
        display:                block;
        height:                 50px;
        margin-left:            -1px;
        @include opacity(.3);
        @include scaleXY(1,1);
        @include transform-origin(center bottom);
        @include transitions(all .5s $easeInOutQuart);
        width:                  1px;
    }
    .alternative--skin &
    {
        &:before
        {
            @include scaleXY(1,0);
        }
    }
    .is--ready &
    {
        @include opacity(1);
    }
    &.for--hover
    {
        @include opacity(0);
        &:before
        {
            @include transitions(all 0s none);
        }
        .is--hovered &
        {
            @include opacity(1);
            &:before
            {
                @include scaleXY(1,1);
            }
        }
    }
}

.feature
{
    @include absoluteLeft(0,0);
    background:             red;
    @include border-radius($timeline-feature-size);
    height:                 $timeline-feature-size;
    @include opacity(0);
    @include scale(1);
    @include transitions(transform .5s $easeInOutQuart , background .5s $easeInOutQuart , opacity .5s $easeInOutQuart);
    width:                  $timeline-feature-size;
    z-index:                10;
    .shape
    {
        @include absoluteLeft(50%,50%);
        height:                 30px;
        @include opacity(0);
        @include translate(-50%,-50%);
        width:                  30px;
        z-index:                1;
        svg
        {
            height:                 100%;
            width:                  100%;
        }
    }
    .alternative--skin &
    {
        @include scale(0);
    }
    &.for--hover
    {
        @include scale(0);
        -webkit-transition: transform .5s $easeInOutQuart !important;
           -moz-transition: transform .5s $easeInOutQuart !important;
             -o-transition: transform .5s $easeInOutQuart !important;
            -ms-transition: transform .5s $easeInOutQuart !important;
                transition: transform .5s $easeInOutQuart !important;
        .is--hovered &
        {
            @include scale(1);
        }
    }

    .is--ready &
    {
        @include opacity(1);
    }
    &.for--booking
    {
        background:             #ffe400;
        .shape--hotel
        {
            @include opacity(1);
        }
        .feature_description
        {
            @include translate(68px,6px);
        }
    }
    &.for--syncing
    {
        background:             #00e4ff;
        .shape--plane
        {
            @include translate(-50%,-40%);
            @include opacity(1);
        }
        .feature_description
        {
            @include translate(68px,6px);
        }
    }
    &.for--tracking
    {
        background:             #e386f7;
        .shape--car
        {
            @include opacity(1);
        }
        .feature_description
        {
            @include translate(68px,6px);
        }
    }
    &.for--expense
    {
        background:             #ff8b35;
        .shape--food
        {
            @include opacity(1);
        }
        .feature_description
        {
            text-align:         right;
            @include translate(-212px,6px);
        }
    }
}

.feature_description
{
    @include absoluteLeft(0,0);
    @include transitions(all .5s $easeInOutQuart);
    width:                  200px;
    .type
    {
        color:                  $white;
        font:                   300 1.6em $roboto;
    }
    .value
    {
        color:                  $white;
        font:                   700 1.6em $roboto;
    }
    .alternative--skin &
    {
        visibility:                 hidden;
    }
    .for--hover &
    {
        -webkit-transition: none !important;
           -moz-transition: none !important;
             -o-transition: none !important;
            -ms-transition: none !important;
                transition: none !important;
    }
}

// Timeline
//=========

.timeline
{
    @include                absoluteLeft(50%,50%);
    height:                 $timeline-size;
    @include opacity(1);
    @include perspective(500px);
    pointer-events:         none;
    @include translate(-50%,-50%);
    @include transitions(opacity 1s $easeInOutQuad);
    width:                  $timeline-size;
    z-index:                $global-zindex + 10;
    .is--ended &
    {
        @include opacity(0);
        opacity:                0 !important;
    }
}

.timeline__anchors
{
    @include absoluteLeft(0,0);
    height:                 $timeline-size;
    pointer-events:         none;
    visibility:             hidden;
    width:                  $timeline-size;
}

.timeline__anchor
{
    background:             transparent;
    cursor:                 pointer;
    height:                 $timeline-anchor-size;
    pointer-events:         auto;
    position:               absolute;
    @include transitions(background .3s $easeInOutQuad);
    width:                  $timeline-anchor-size;
    z-index:                1;
    &:before
    {
        @include absoluteLeft(50%,50%);
        background:             transparent;
        @include border-radius($timeline-anchor-real-size - 4);
        content:                " ";
        display:                block;
        height:                 $timeline-anchor-real-size - 4;
        @include transitions(background .3s $easeInOutQuad);
        @include translate(-50%,-50%);
        width:                  $timeline-anchor-real-size - 4;
        z-index:                1;
    }
    &:after
    {
        @include absoluteLeft(50%,50%);
        background:             $white;
        @include border-radius($timeline-anchor-real-size);
        content:                " ";
        display:                block;
        height:                 $timeline-anchor-real-size;
        @include transitions(background .3s $easeInOutQuad);
        @include translate(-50%,-50%);
        width:                  $timeline-anchor-real-size;
    }
    &.is--alternative
    {
        &:before
        {
            background:             $black;
        }
    }
    .alternative--skin &
    {
        &:after
        {
            background:             #d5d4d4;
        }
        &.is--alternative
        {
            &:before
                {
                    background:             $white;
                }
            }
        }
    &.active
    {
        &:after
        {
            background:             $white !important;
        }
        .alternative--skin &
        {
            &:after
            {
                background:             $blue-02 !important;
            }

        }
    }
}

#timelineSvg
{
    @include absoluteLeft(0,0);
    height:                 $timeline-size;
    @include opacity(0);
    pointer-events:         none;
    width:                  $timeline-size;
    .line,.shape
    {
        @include transitions(all .4s ease-out);
    }
    .shape {
        pointer-events:         stroke;
    }
    .alternative--skin &
    {
        .shape
        {
            stroke:                 rgba(159,159,159,.3);
        }
    }
    .is--ready &
    {
        @include opacity(1);
    }
}