@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

.layout__home
{
    height:                 auto;
    overflow:               hidden;
    @include perspective(1000px);
    width:                  100%;
    ::-webkit-scrollbar {
        width: 8px;
    }
}


.scrollbar
{
    @include absoluteRight(0,0);
    background:             $white;
    display:                none;
    height:                 100vh;
    pointer-events:         none;
    @include transitions(background $easing-opacity);
    width:                  8px;
    .handle
    {
        @include absoluteLeft(0,0);
        height:                 100px;
        @include transitions(background $easing-opacity);
        width:                  100%;
        &.for--booking
        {
            background:             #ffe400;
        }
        &.for--syncing
        {
            background:             #00e4ff;
        }
        &.for--tracking
        {
            background:             #e386f7;
        }
        &.for--expense
        {
            background:             #ff8b35;
        }
        &.for--final
        {
            background:             $blue-03 !important;
        }
    }
    .alternative--skin &
    {
        background:             #f2f2f2;
    }
}

.webkit
{
    .scrollbar
    {
        display:                block;
    }
}



// Loader
//=======
.main-loader
{
    @include absoluteLeft(0,0);
    // background:             $blue-02;
    height:                 100vh;
    width:                  100vw;
    z-index:                $global-zindex + 1;
    .fill
    {
        @include absoluteLeft(0,0);
        background:             $blue-02;
        height:                 100vh;
        width:                  100vw;
    }
    .progress
    {
        @include absoluteLeft(50%,50%);
        color:                  $white;
        font:                   700 56em/0.8 $roboto;
        height:                 100vh;
        overflow:               hidden;
        text-align:             center;
        @include translate(-50%,-50%);
        width:                  100%;
        .value
        {
            font-family:            $roboto;
            @include inlineBlock;
            line-height:            100vh;
            margin:                 0 -50px;
            visibility:             hidden;
            div
            {
                display:                block;
                height:                 100vw;
            }
        }
    }
    .number
    {
        @include absoluteLeft(50%,50%);
        color:                  $white;
        font:                   700 10em/1 $roboto;
        @include translate(-50%,-50%);
        z-index:                1;
    }
    .line
    {
        @include absoluteLeft(0,50%);
        background:             $white;
        height:                 2px;
        @include translate(0,-50%);
        visibility:             hidden;
        width:                  100vw;
    }
    .dots
    {
        @include absoluteLeft(0,0);
        height:                 100vh;
        visibility:             hidden;
        width:                  100vw;
        .dot
        {
            @include absoluteLeft(50%,50%);
            background:             $white;
            @include border-radius($timeline-anchor-real-size);
            display:                block;
            height:                 $timeline-anchor-real-size;
            margin:                 (-($timeline-anchor-real-size/2)) 0 0 (-($timeline-anchor-real-size/2));
            width:                  $timeline-anchor-real-size;
        }
    }
    .circle,.circleReverse
    {
        @include absoluteLeft(50%,50%);
        border:                 2px solid rgba(255,255,255,.5);
        clip:                   rect(0,0,250px,0);
        display:                block;
        @include border-radius($timeline-size-real);
        height:                 $timeline-size-real;
        margin:                 (-( ($timeline-size-real + 3) /2)) 0 0 (-( ( $timeline-size-real + 3)/2));
        width:                  $timeline-size-real;
    }
    .circleReverse
    {
        clip:                   rect(250px, 500px, 500px, 500px);
    }
    .logofull
    {
        @include absoluteLeft(50%,50%);
        height:                 250px;
        @include translate(-50%,-50%);
        visibility:             hidden;
        svg
        {
            display:            block;
            fill:               $white;
            height:             100%;
            width:              100%;
        }
    }
    .button
    {
        @include absoluteLeft(50%,auto);
        bottom:             80px;
        cursor:             pointer;
        @include border-radius($loader-button-size);
        height:                 $loader-button-size;
        margin-left:            (-($loader-button-size/2));
        @include transitions(all .3s $easeInOutBack);
        @include transform-origin(50% 50%);
        width:                  $loader-button-size;
        &:after
        {
            @include absoluteLeft(0,0);
            background:             $white;
            content:                " ";
            display:                block;
            @include border-radius($loader-button-size);
            height:                 $loader-button-size;
            @include opacity(0);
            @include transform(1, 0, 0, -20px, 0, 0);
            @include transitions(all .5s $easeInOutCubic);
            @include transition-delay(1s);
            width:                  $loader-button-size;
        }
        &:hover
        {
            @include scale(.8);
        }
        .symbol
        {
            @include absoluteLeft(50%,50%);
            display:            block;
            height:             2px;
            @include translate(-50%,-50%);
            width:              20px;
            z-index:            1;
            &:before
            {
                background:         $blue-02;
                content:            " ";
                display:            block;
                float:              left;
                height:             2px;
                left:               2px;
                position:           relative;
                @include rotate(0);
                @include transitions(all .3s $easeInOutQuad);
                @include transition-delay(1s);
                width:              10px;
            }
            &:after
            {
                background:         $blue-02;
                content:            " ";
                display:            block;
                float:              left;
                height:             2px;
                left:               -2px;
                position:           relative;
                @include rotate(0);
                @include transitions(all .3s $easeInOutQuad);
                @include transition-delay(1s);
                width:              10px;
            }
        }
    }
    &.is--complete
    {
        .button
        {
            &:after
            {
                @include transform(1, 0, 0, 0, 0, 0);
                @include opacity(1);
            }
            .symbol
            {
                &:before
                {
                    @include rotate(45deg);
                }
                &:after
                {
                    @include rotate(-45deg);
                }
            }
        }
    }
}

// Counter
//========

.counter
{
    @include absoluteLeft(50%,50%);
    display:                table;
    height:                 $timeline-size;
    @include opacity(1);
    pointer-events:         none;
    text-align:             center;
    @include translate(-50%,-50%);
    @include transitions(opacity 1s $easeInOutQuad);
    width:                  $timeline-size;
    z-index:                $global-zindex + 2;
    .is--ended &
    {
        @include opacity(0);
        opacity:                0 !important;
    }
}

.counter__title
{
    @include absoluteLeft(auto,auto);
    color:                  $white;
    font:                   500 1.4em $roboto;
    height:                 75px;
    letter-spacing:         1px;
    overflow:               hidden;
    margin:                 0 0 0;
    text-align:             center;
    text-transform:         uppercase;
    @include transitions(color 1s $easeInOutQuad);
    @include translate(0,-15px);
    visibility:             hidden;
    width:                  100%;
    span
    {
        @include absoluteLeft(50%,0);
        @include inlineBlock;
        @include translate(-50%,0);
        width:              320px;
        &:nth-child(2)
        {
            visibility:             hidden;
        }
    }
    .alternative--skin &
    {
        color:                  $blue-02;
    }
}

.counter__subtitle
{
    color:                  $white;
    font:                   500 1.4em/1.7 $roboto;
    letter-spacing:         1px;
    height:                 95px;
    margin:                 0 0 15px;
    overflow:               hidden;
    position:               absolute;
    text-align:             center;
    text-transform:         uppercase;
    @include translate(0,-59px);
    visibility:             hidden;
    width:                  100%;
    span
    {
        display:                block;
    }
}


.counter__currency
{
    color:                  $white;
    font:                   700 6em/1 $roboto;
    float:                  left;
    left:                   65px;
    position:               relative;
    text-transform:         uppercase;
    top:                    19px;
    @include transitions(color 1s $easeInOutQuad);
    visibility:             hidden;
    .alternative--skin &
    {
        color:                  $blue-02;
    }
}

.counter__number,.counter__numberAlt
{
    color:                  $white;
    font:                   700 10em/1 $roboto !important;
    text-transform:         uppercase;
}

.counter__number
{
    visibility:             hidden;
}

.counter__numberAlt
{
    @include absoluteLeft(110px,194px);
    color:                  $blue-02;
    @include opacity(0);
    position:               absolute !important;
    @include transitions(opacity 1s $easeInOutQuad);
    .alternative--skin &
    {
        @include opacity(1);
    }
    .safari &,.firefox &
    {
        left:                   109px;
    }
    .tablet &
    {
        top:                    191px;
    }
    .ie &
    {
        top:                    191px;
    }
    .ie11 &
    {
        top:                    194px !important;
    }
}

.counter
{
    .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner,.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner
    {
        @include transitions(all 1s $easeInOutQuad);
    }
}

// Slide
//======

.slideshow
{
    height:                 auto;
    overflow-y:             auto;
    overflow-x:             hidden;
    width:                  100%;
}

.slideshow__wrapper
{
    height:                 100vh;
    width:                  100vw;
}

.slideshow__controller
{
    @include absoluteLeft(50%,50%);
    height:                 $timeline-size;
    pointer-events:         none;
    @include translate(-50%,-50%);
    width:                  $timeline-size;
}

.slideshow__item
{
    background:             $white;
    height:                 100vh;
    overflow:               hidden;
    position:               relative;
    width:                  100vw;
    &.with--image
    {
        background:             $black;
        .bg
        {
            @include absoluteLeft(0,0);
            @include backgroundSize(cover);
            background-position:    center center;
            background-repeat:      no-repeat;
            height:                 100vh;
            @include opacity(.8);
            width:                  100vw;
        }
        .videobg
        {
            @include absoluteLeft(50%,50%);
            height:                     auto;
            position:                   relative;
            min-height:                 100%;
            min-width:                  100%;
            @include opacity(.8);
            @include translate(-50%,-50%);
            width:                      auto;
            z-index:                    0;
        }

    }
    .desktop &
    {
        .bg.is--ready
        {
            display:                none;
        }
    }
    .ie &
    {
        .bg
        {
            display:                block !important;
        }
    }
}

.slideshow__title
{
    color:                  $grey-01;
    font:                   300 4.8em/1 $roboto;
}

.slideshow__description
{
    color:                  $grey-01;
    font:                   300 2.2em/1.4 $roboto;
    margin-top:             25px;
}

.slideshow__nested
{
    position:               absolute;
    width:                  30vw;
    z-index:                1;
}

.slideshow__preview
{
    @include absoluteLeft(50%,50%);
    display:                block;
    height:                 $slideshow-img-height;
    margin:                 (-($slideshow-img-height/2)) 0 0 (-($slideshow-img-width/2));
    width:                  $slideshow-img-width;
}

#tweenController
{
    @include absoluteLeft(9999px,9999px);
    height:                 100px;
    width:                  100px;
}

#flashExpense
{
    @include absoluteLeft(0,0);
    background:             $black;
    @include opacity(0);
    height:                 100vh;
    pointer-events:         none;
    width:                  100vw;
    z-index:                10;
}

// Booking
//========

.for--booking
{
    .slideshow__nested
    {
        right:                  100px;
        top:                    100px;
    }
    .slideshow__preview
    {
        &.to--left
        {
            left:                   30%;
        }
        &.to--right
        {
            left:                   70%;
            top:                    90%;
        }
    }
}

// Syncing
//========

.for--syncing
{
    .slideshow__nested
    {
        left:                   100px;
        top:                    100px;
    }
    .slideshow__preview
    {
        &.to--left
        {
            left:                   30%;
            top:                    90%;
        }
        &.to--right
        {
            left:                   70%;
        }
    }
}

// Tracking
//=========

.for--tracking
{
    .slideshow__nested
    {
        bottom:                 100px;
        left:                   100px;

    }
    .slideshow__preview
    {

        &.to--left
        {
            height:                 476px;
            left:                   30%;
            margin:                 (-(476px / 2)) 0 0 (-(453px / 2));
            top:                    10%;
            width:                  453px;
        }
        &.to--right
        {
            height:                 565px;
            left:                   70%;
            margin:                 (-(565px / 2)) 0 0 (-(461px / 2));
            width:                  461px;
        }
    }
}

// Expense
//========

.for--expense
{
    background:             #f8f8f8;
    .slideshow__nested
    {
        @include box-sizing(border-box);
        height:                 100vh;
        position:               relative;
        width:                  100vw;
        .slideshow__title
        {
            @include absoluteLeft(100px,100px);
            width:                  25vw;
        }
        .slideshow__description
        {
            @include absoluteRight(100px,auto);
            bottom:                 100px;
            text-align:             right;
            width:                  25vw;
        }
    }
    .slideshow__preview
    {

        &.to--left
        {
            height:                 775px;
            left:                   30%;
            margin:                 (-(775px / 2)) 0 0 (-(442px / 2));
            top:                    95%;
            width:                  442px;
            z-index:                1;
        }
        &.to--right
        {
            height:                 422px;
            left:                   70%;
            margin:                 (-(422px / 2)) 0 0 (-(279px / 2));
            top:                    10%;
            width:                  279px;
            z-index:                0;
        }
    }
}

// For final
//==========

.for--final
{
    background:             $blue-02 !important;
}

.try
{
    @include absoluteLeft(50%,50%);
    background:             $blue-03;
    @include border-radius($timeline-size - 4px);
    height:                 $timeline-size - 4px;
    text-align:             center;
    @include translate(-50%,-50%);
    width:                  $timeline-size - 4px;
    z-index:                1;
}

.try__wrapper
{
    position:               relative;
    top:                    50%;
    @include translate(0,-50%);
}

.try__symbol
{
    color:                  $white;
    font:                   700 72px $roboto;
    margin:                 0 0 15px;
}

.try__description
{
    color:                  $white;
    font:                   300 1.8em/1.4 $roboto;
    margin:                 0 0 50px;
    padding:                0 18%;
}

.try__button
{
    @include border-radius(25px);
    background:             rgba(255,255,255,1);
    border:                 1px solid $white;
    color:                  $black;
    font:                   400 1.6em/1 $roboto;
    @include inlineBlock;
    padding:                15px 25px;
    @include transitions(all $easing-01);
    z-index:                $global-zindex;
    &:hover
    {
        color:                  $blue-01;
    }
}

// Dollars tween
//==============

.dollarsTween
{
    @include absoluteLeft(0,0);
    height:                 100vh;
    @include perspective(1000px);
    pointer-events:         none;
    width:                  100vw;
    z-index:                0;
    .symbol
    {
        @include absoluteLeft(50%,50%);
        color:                  #3d8cf9;
        font:                   700 72px/1 $roboto;
        margin:                 -36px 0 0 -20.5px;
    }
}