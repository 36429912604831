@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

.layout__annex,.layout__pricing,.layout__features
{
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background:             $blue-02;
    }
}

// Annex
//======
.layout__annex
{
    height:                 auto;
    overflow-y:             auto;
    overflow-x:             hidden;
    width:                  100%;
    .wrapper
    {
        height:                 100vh;
    }
}

.page-slideshow
{
    height:                 auto;
    position:               relative;
    width:                  100%;
}

.page-slideshow__item
{
    @include absoluteLeft(0,0);
    height:                 100%;
    width:                  100%;
    &.disabled {
        visibility:             hidden;
    }
    &.enabled {
        visibility:             visible;
    }
}

// Pricing
//========
.layout__pricing
{
    height:                 auto;
    overflow-y:             auto;
    overflow-x:             hidden;
    width:                  100%;
    .wrapper
    {
        height:                 100vh;
    }
    .container
    {
        margin:                 0 auto;
        overflow:               hidden;
        &.prices
        {
            background:             $grey-02;
            @include box-sizing(border-box);
            padding:                130px 0;
            .introduction
            {
                color:                  #707070;
                font:                   300 6em/1 $roboto;
                margin:                 0 0 55px;
                text-align:             center;
            }
            .brand
            {
                background:             #e6e6e6;
                display:                table;
                height:                 398px;
                overflow:               hidden;
                position:               relative;
                text-align:             center;
                width:                  380px;
                &:before
                {
                    @include absoluteLeft(0,0);
                    background:             #72b5ff;
                    content:                " ";
                    display:                block;
                    height:                 100%;
                    @include opacity(0);
                    @include transitions(all $easing-opacity);
                    width:                  100%;
                    z-index:                1;
                }
                &:after
                {
                    @include absoluteLeft(50%,50%);
                    @include border-radius(25px);
                    background:             $blue-02;
                    content:                attr(data-hover);
                    color:                  $white;
                    display:                block;
                    font:                   400 1.8em/1 $roboto;
                    @include opacity(0);
                    @include transitions(all $easing-opacity);
                    padding:                15px 25px;
                    @include translate(-50%,-50%);
                    z-index:                1;
                }
                &:hover
                {
                    &:before
                    {
                        @include opacity(.9);
                    }
                    &:after
                    {
                        @include opacity(1);
                    }
                }
                .title
                {
                    color:                  $blue-02;
                    font:                   400 2.4em $roboto;
                    text-transform:         uppercase;
                }
                .subtitle
                {
                    color:                  #9f9f9f;
                    font:                   400 1.6em $roboto;
                }
                .price
                {
                    color:                  $blue-02;
                    font:                   300 20em/1 $roboto;
                    margin:                 40px auto 10px;
                    position:               relative;
                    width:                  220px;
                    .currency
                    {
                        @include absoluteLeft(0,29px);
                        font-size:              80px;
                    }
                    .caret
                    {
                        @include absoluteRight(0,40px);
                        color:                  #90c2fe;
                        @include inlineBlock;
                        font-size:              16px;
                        line-height:            18px;
                    }
                }
            }
            .features
            {
                background:             $white;
                list-style:             none;
                overflow:               hidden;
                li
                {
                    border-bottom:          1px solid #e7e7e7;
                    color:                  #7a7a7a;
                    font:                   400 1.6em $roboto;
                    margin:                 0 40px;
                    padding:                25px 0;
                    &:last-child
                    {
                        border-bottom:          0px;
                    }
                }
            }
        }
        &.qa
        {
            @include box-sizing(border-box);
            padding:                130px 0;
            .side
            {
                width:                  350px;
            }
            .introduction
            {
                color:                  $blue-02;
                font:                   400 6em/1 $roboto;
                margin:                 0 0 55px;
                text-align:             center;
            }
            .question
            {
                color:                  $black;
                font:                   400 2.2em/1 $roboto;
                margin:                 0 0 15px;
            }
            .answer
            {
                color:                  #969696;
                font:                   300 1.8em/1.3 $roboto;
                text-align:             justify;
            }
        }
        .nested
        {
            margin:             0 auto;
            width:              775px;
        }
        .side
        {
            overflow:           hidden;
            width:              380px;
            &:nth-of-type(1)
            {
                float:              left;
            }
            &:nth-of-type(2)
            {
                float:              right;
                .brand
                {
                    background:         $blue-02;
                    .title,.price
                    {
                        color:              $white;
                    }
                    .subtitle
                    {
                        color:              #9eccff;
                    }
                }
            }
        }
    }
}

// Feature
//========
.layout__features
{
    height:                 auto;
    overflow-y:             auto;
    overflow-x:             hidden;
    width:                  100%;
    .wrapper
    {
        height:                 100vh;
    }
    .container
    {
        background:             $grey-02;
        margin:                 0 auto;
        padding:                130px 0;
        overflow:               hidden;
    }
    .introduction
    {
        color:                  #242424;
        font:                   300 6em/1 $roboto;
        margin:                 0 0 55px;
        text-align:             center;
    }
}

.feature__item
{
    margin:                 0 auto;
    width:                  875px;
    .title
    {
        color:                  #848484;
        float:                  left;
        font:                   300 2.4em/1 $roboto;
        margin:                 55px 0 0;
        position:               relative;
        right:                  45px;
        text-align:             right;
        width:                  30%;
    }
    .subtitle
    {
        color:                  $black;
        font:                   400 1.8em/1 $roboto;
        margin:                 0 0 15px;
    }
    .description
    {
        color:                  #969696;
        font:                   300 1.4em/1.3 $roboto;
        text-align:             justify;
    }
    .nested
    {
        background:             $white;
        @include box-sizing(border-box);
        float:                  left;
        padding:                55px 40px;
        width:                  70%;
        &:after
        {
            background:             #e7e7e7;
            content:                " ";
            display:                block;
            height:                 1px;
            margin:                 0 auto;
            position:               relative;
            top:                    -50px;
            width:                  100%;
        }
        .side
        {
            width:              46%;
            &:nth-of-type(1)
            {
                float:              left;
            }
            &:nth-of-type(2)
            {
                float:              right;
            }
        }
    }
    &:first-of-type
    {
        .nested
        {
            &:after
            {
                display:            none;
            }
        }
    }
}