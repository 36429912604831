@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

.center__context
{
    display:                table-cell;
    vertical-align:         middle;
}

// GENERIC
//========

.gen__brand
{
    background:             $blue-02;
    @include box-sizing(border-box);
    padding:                130px;
    position:               relative;
}

.gen__content
{
    background:             $white;
    @include box-sizing(border-box);
    padding:                130px;
    position:               relative;
}

.gen__description
{
    color:                  #969696;
    font:                   400 1.8em/1.4 $roboto;
    margin:                 0 0 130px;
    h3
    {
        color:                  $black;
        font-size:              1.2em;
    }
    p
    {
        margin:                 20px 0;
    }
    a
    {
        color:                  $blue-01;
        &:hover
        {
            text-decoration:        underline;
        }
    }
}

.gen__title
{
    color:                  $blue-02;
    font:                   300 6em/1.2 $roboto;
    margin:                 0;
}

.gen__menu
{
    list-style:             none;
    overflow:               hidden;
    li
    {
        color:                  #a5cdff;
        font:                   300 3em $roboto;
        @include inlineBlock;
        margin:                 0 25px 0 0;
        a
        {
            color:                  #a5cdff;
            @include transitions(color $easing-opacity);
        }
        &.active
        {
            a
            {
                color:              $white;
            }
        }
    }
}
