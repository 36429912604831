@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

body {
    background:             $white;
    overflow:               hidden;
}

// Logo
//=====
.logo
{
    @include absoluteLeft($logo-size,$logo-size);
    height:                 $logo-size;
    width:                  $logo-size;
    z-index:                $global-zindex;
    svg
    {
        height:                 100%;
        width:                  100%;
        path
        {
            stroke:                 $white;
            @include transitions(all 1s $easeInOutQuad);
            &:nth-child(1)
            {
                .chrome &
                {
                    @include rotate(0deg);
                    @include transform-origin(50% 50%);
                }

            }
        }
    }
    &.active,&:hover
    {
        svg {
            path
            {
                &:nth-child(1)
                {
                    .chrome &
                    {
                        @include rotate(360deg);
                    }
                }
            }
        }
    }
    .alternative--skin &
    {
        svg
        {
            path
            {
                stroke:         $blue-02;
            }
        }
    }
    .mainnav--opened &,.final--skin &
    {
        svg
        {
            path
            {
                stroke:         $white !important;
            }
        }
    }
    .mainnav--opened &,&.disabled
    {
        pointer-events:         none;
    }
}

// Register button
//=============
.register__button
{
    @include absoluteRight($logo-size + 100,$logo-size);
    @include border-radius(25px);
    background:             rgba(255,255,255,0);
    border:                 1px solid $white;
    color:                  $white;
    font:                   400 1.6em/1 $roboto;
    @include inlineBlock;
    padding:                15px 25px;
    @include transitions(all $easing-01);
    z-index:                $global-zindex;
    &:hover
    {
        background:             rgba(255,255,255,1);
        color:                  $blue-01;
    }
    .alternative--skin &
    {
        border-color:           #d9d9d9;
        color:                  $blue-02;
    }
    .final--skin &
    {
        color:                  $white;
        &:hover
        {
            color:                  $blue-02;
        }
    }
}

// Login button
//=============
.login__button
{
    @include absoluteRight($logo-size,$logo-size);
    color:                  $white;
    font:                   400 1.6em/1 $roboto;
    @include inlineBlock;
    padding:                15px 25px;
    @include transitions(all $easing-01);
    z-index:                $global-zindex;
    &:hover
    {
        color:                  $blue-01;
    }
    .alternative--skin &
    {
        color:                  $blue-02;
    }
    .final--skin &
    {
        color:                  $white;
        &:hover
        {
            color:                  $blue-02;
        }
    }
}

// Download button
//================
.button__download
{
    background:             url($COMP_IMG+'button-download.png') 0 0 no-repeat;
    display:                block;
    height:                 54px;
    width:                  174px;
}

// Master
//=======
#master {
    height:                 auto;
    min-height:             100%;
    overflow:               hidden;
    position:               relative;
    width:                  100%;
}

// Main content
//=============
#main-content {
    display:                block;
    height:                 auto;
    overflow:               hidden;
    width:                  100%;
    z-index:                $global-zindex - 1;
    .the-view {
        overflow:               visible;
        width:                  100%;
    }
}

// Transition
//===========
#main-transition
{
    @include absoluteLeft(0,0);
    background:             $blue-01;
    height:                 100vh;
    pointer-events:         none;
    visibility:             hidden;
    width:                  100vw;
    z-index:                $global-zindex - 2;
}
