@charset "utf-8";
@import "../../node_modules/mixins-sass/src/mixins.scss";
@import "base.param.scss";
@import "sprite.scss";

.tablet,.mobile
{
    .layout__features,.layout__pricing,.layout__annex
    {
        overflow-y: scroll; /* has to be scroll, not auto */
      -webkit-overflow-scrolling: touch;
    }
    .slideshow__title
    {
        font-size:          3.8em;
    }
    .slideshow__description
    {
        font-size:          1.8em;
        position:           relative;
    }
}


@media only screen and (max-width : 1024px)
{
    .slideshow__preview
    {
        height:                 $slideshow-img-height - 100;
        margin:                 (-(($slideshow-img-height - 100) /2)) 0 0 (-(($slideshow-img-width - 100 )/2));
        width:                  $slideshow-img-width - 100;
    }

    .slideshow__title
    {
        font:                   300 3.4em/1 $roboto;
    }

    .slideshow__description
    {
        font:                   300 1.6em/1.4 $roboto;
    }

    .for--tracking
    {
        .slideshow__preview
        {
            &.to--left
            {
                height:                 416px;
                margin:                 (-(416px / 2)) 0 0 (-(393px / 2));
                width:                  393px;
            }
            &.to--right
            {
                height:                 505px;
                margin:                 (-(505px / 2)) 0 0 (-(401px / 2));
                width:                  401px;
            }
        }
    }


    .for--expense
    {
        .slideshow__preview
        {
            &.to--left
            {
                height:                 705px;
                margin:                 (-(705px / 2)) 0 0 (-(372px / 2));
                width:                  372px;
            }
            &.to--right
            {
                height:                 352px;
                margin:                 (-(352px / 2)) 0 0 (-(209px / 2));
                width:                  209px;
            }
        }
    }

}

@media only screen and (min-width : 1025px) and (max-width : 1280px)
{
    .slideshow__title
    {
        font:                   300 3.6em/1 $roboto;
    }

    .slideshow__description
    {
        font:                   300 1.6em/1.4 $roboto;
    }
}

@media only screen and (min-width : 1281px) and (max-width : 1366px)
{
    .slideshow__title
    {
        font:                   300 3.8em/1 $roboto;
    }

    .slideshow__description
    {
        font:                   300 1.8em/1.4 $roboto;
    }
}